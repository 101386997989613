<!--
    @name: widget-switch
    @description：widget-switch
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <div>
    <div v-if="disabled" class="input-show">{{ element.value ? $t('FormItem.widget-switch.079870-0') : $t('FormItem.widget-switch.079870-1') }}</div>
    <el-switch
      v-else
      v-model="element.value"
      :disabled="disabled"
      @change="triggerInteractive"
    ></el-switch>
  </div>
</template>

<script>
import {Switch} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetSwitch",
  components: {
    'el-switch': Switch
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  created() {
    this.triggerInteractive(this.element.value,'auto')
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
